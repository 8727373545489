<script>
import { onMounted, onUnmounted, ref, inject } from 'vue'
import { signIn } from '@/http/api'
import router from '@/routes'
import { Toast } from 'vant'
import { needLoading, userAuth } from '@/reactive'
import md5 from 'md5'
import { useRoute, useRouter } from 'vue-router'

// import wx from '@/utils/weixin'
export default {
  setup() {
    let route = useRoute()
    let tel = ref('')
    let password = ref('')
    let checked = ref(false)
    let serviceTel = inject('serviceTel')
        
    let onSubmit = async (fields) => {
      if(needLoading.value) {
        return false
      }
      needLoading.value = true
      signIn({
        tel: fields.tel,
        loginPwd: md5(fields.password),
        referrerRole: route.query.referrerRole,
        referrerId: route.query.referrerId 
      }).then(data => {
        if(data.result) {
          Toast.success('登录成功!')
          userAuth.value = {
            ...userAuth,
            ...data.result
          }
          window.localStorage.setItem('userAuth', JSON.stringify(data.result));
          
          if(data.result.showStatus === 'INDEX'){
            router.push('/')
          }
          else if(data.result.showStatus === 'REAL_AUTH') {
            router.push('/verified')
          }
          else if(data.result.showStatus === 'AGRT_SURE') {
            router.push('/trust?type=broker')
          }
          else if(data.result.showStatus === 'SERVICE_OPEN') {
            router.push('/open-service')
          }
        }
        
      }).catch(err => {})
    }

    return {
      tel,
      password,
      checked,
      onSubmit,
      serviceTel
    }
  }
}
</script>
<template>
  <div class="page">
    <van-form @submit="onSubmit" validate-trigger="onSubmit">
      <h3>账号登录</h3>
      <van-field
        v-model="tel"
        name="tel"
        type="number"
        maxlength="11"
        placeholder="请输入手机号码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{
          required: true,
          message: '请输入手机号码' 
        }, {
          pattern: /^1[3456789][0-9]{9}$/, 
          message: '请输入正确手机号格式'
        }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        placeholder="请输入密码"
        style="border-bottom:1px solid #dbdbdb"
        :rules="[{ 
          required: true, 
          message: '请输入密码' 
        }, {
          pattern: /^.{6,16}$/, 
          message: '请输入6-16位任意组合密码'
        }]"
      />
      <div style="margin: 30px 0 0;">
        <van-button block type="primary" native-type="submit">登录</van-button>
      </div>
      <div style="margin-top:20px; text-align:center">
        <span style="color:#666; font-size:.28rem" @click="$router.push('/forget')" >忘记密码</span>
        <p style=" margin-top: 20px;font-size:0.24rem; color:#666">
          登录即代表已阅读并同意<span style="color: #00ABEB" @click="$router.push('/blend-view/agreement?agreementType=BROKER_PROTOCOL')">《猪耳朵渠道商协议》</span>
          <!-- <van-checkbox v-model="checked" checked-color="#00abeb" icon-size="14px" shape="square">
            
          </van-checkbox> -->
        </p>
      </div>
    </van-form>
    <div class="concat">
      <p>账号开通请联系客服<a style="display: block;color: #00ABEB" :href="`tel:${serviceTel}`">{{serviceTel}}</a></p>
    </div>
  </div>
</template>
<style scoped>
  .page {height: 100%; overflow: auto;padding: 0 .8rem; box-sizing: border-box}
  .concat { line-height: 1.5; font-size: .24rem; text-align:center }
  .concat p {  padding-top:80%}
  h3 { margin:1rem 0; font-size:.46rem; }
  .van-cell::after { border:none }
</style>